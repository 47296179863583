import React from "react"
import aetna from "../assets/images/insurances/1_Heart_Aetna_logo_sm_rgb_violet.png"
import blue from "../assets/images/insurances/blue-cross-blue-shield-logo-vector.png"
import united from "../assets/images/insurances/unitedhealthcare-emblem.png"
import cigna from "../assets/images/insurances/cigna-logo.png"

const insurances = [
  { name: "Blue Cross Blue Shield", logo: blue },
  { name: "United Health Care", logo: united },
  { name: "Aetna", logo: aetna },
]
export default React.memo(function Schedule() {
  return (
    <section className="container-fluid insurance-container" id="home">
      <div className="insurance-wrapper">
        <div className="card bg-light border-0" id="appointments">
          <div className="card-body">
            <a
              href="https://urbancarechiropractic.schedulista.com/"
              className="card-title fs-3 text-decoration-none text-dark"
              target="_blank"
              rel="noopener"
            >
              <h2 className="card-title fs-3 h4" style={{ paddingTop: "24px" }}>
                Most Health Insurance Plans Are Accepted
              </h2>
              <h3 className="card-subtitle mb-2 text-muted h5">
                Ask about insurance coverage
              </h3>
            </a>
            <div className="row justify-content-center my-3">
              {insurances.map((insurance, i) => (
                <div
                  key={i}
                  className="col-7 col-md card border-primary rounded-2 m-1 text-decoration-none text-dark product-card "
                >
                  <div className="card-body text-center insurance-card">
                    <h4 className="card-title text-primary h5">
                      {insurance.name}
                    </h4>
                    <div>
                      <img
                        className="insurance-logo"
                        src={insurance.logo}
                        alt={insurance.name}
                        style={{
                          padding: `${
                            insurance.name === "Aetna" ? "20px 0" : "0"
                          }`,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

import React from "react"
import { graphql } from "gatsby"
import Experts from "../components/Experts"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import Infobar from "../components/Infobar"
import Location from "../components/Location"
import Reviews from "../components/Reviews"
import Schedule from "../components/Schedule"
import Services from "../components/Services"
import teamImage from "../assets/images/team/team.jpg"

function HomePage({ data }) {
  const reviews = data.reviews.nodes
  const services = data.services.nodes
  const experts = data.experts.nodes
  return (
    <div>
      <SEO />
      <Schedule />
      <Reviews reviews={reviews} />
      <Experts experts={experts} team={teamImage} />

      <Services services={services} />
      <Location />
      <aside
        className="container-fluid d-flex align-items-center justify-content-center bg-light parallax text-light"
        id="quote"
        style={{ minHeight: "30vh" }}
      >
        <div className="row">
          <div className="col text-center">
            <figure>
              <blockquote
                className="blockquote"
                style={{ color: "var(--bs-secondary)" }}
              >
                <p>
                  Don’t miss out on your ticket to living a healthier and
                  happier lifestyle.
                </p>
              </blockquote>
            </figure>
          </div>
        </div>
      </aside>
    </div>
  )
}

export default React.memo(HomePage)

export const query = graphql`
  query {
    posts: allSanityPost {
      nodes {
        date
        description
        id
        link
        title
      }
    }
    reviews: allSanityReview {
      nodes {
        id
        demo
        name
        rating
        review
        photo {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    services: allSanityServices {
      nodes {
        id
        name
        description
        demo
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    experts: allSanityExperts {
      nodes {
        id
        description
        motto
        name
        specialty
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    images: allSanityImages(filter: { active: { eq: true } }) {
      nodes {
        id
        title
        subtitle
        active
        image {
          asset {
            metadata {
              dimensions {
                height
                width
              }
            }
            fluid(maxWidth: 1200) {
              ...GatsbySanityImageFluid
            }
          }
          hotspot {
            y
            x
          }
          crop {
            bottom
            left
            right
            top
          }
        }
      }
    }
  }
`

import React, { useState, useEffect } from "react"

import Img from "gatsby-image"
import defaultUserImage from "../assets/images/icons/user.svg"

const ReviewCard = React.memo(function ReviewCard({
  review,
  isActive,
  onClick,
}) {
  return (
    <div
      key={review.id}
      className="review__card"
      style={{
        transition: "transform 0.2s ease-in-out",
        borderColor: isActive ? "var(--bs-primary)" : "transparent",
      }}
      onClick={onClick}
    >
      <article>
        <div className="review--img__container">
          {review.photo ? (
            <Img fluid={review.photo.asset.fluid} alt={review.name} />
          ) : (
            <img src={defaultUserImage} alt={review.name} />
          )}
        </div>
        <span className="review__stars">
          {Array.from({ length: parseInt(review.rating) }).map((_, i) => (
            <i className="bi bi-star-fill" key={i} />
          ))}
        </span>
        <div>
          <h5>{review.name}</h5>
        </div>
      </article>
    </div>
  )
})

export function Reviews({ reviews }) {
  const [selectedReview, setSelectedReview] = useState(reviews[4])
  const [randomReviews, setRandomReviews] = useState([])

  /* DISPLAY RANDOM REVIEWS */
  const displayCount = 4
  const reviewCount = reviews.length
  const getRandomInt = max => Math.floor(Math.random() * Math.floor(max))
  const displayRandomReviews = () => {
    const randReviews = []
    let rand = null
    while (randReviews.length < displayCount) {
      rand = getRandomInt(reviewCount)
      if (!randReviews.includes(rand)) {
        randReviews.push(rand)
        if (!reviews[rand]) return
      }
    }
    setSelectedReview(reviews[randReviews[0]])
    return randReviews
  }

  useEffect(() => {
    setRandomReviews(displayRandomReviews())
    // setSelectedReview(reviews[randomReviews[0]])
  }, [])

  return (
    <section
      className="container-fluid d-flex align-items-center justify-content-center bg-light p-5 parallax"
      id="reviews"
      style={{ minHeight: "40vh" }}
    >
      <div className="container reviews__section">
        <div className="reviews__content">
          <div>
            <h4 className="card-title fs-3 h4">{selectedReview.name}</h4>
            <p>{selectedReview.review}</p>
          </div>

          {selectedReview.demo && (
            <div className="reviews__youtube">
              <iframe
                width="560"
                height="315"
                src={selectedReview.demo}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
        <div className="reviews__container">
          {randomReviews &&
            reviews
              .filter((_, i) => randomReviews.includes(i))
              .map((review, i) => (
                <ReviewCard
                  review={review}
                  key={i}
                  isActive={review.id === selectedReview.id}
                  onClick={() => setSelectedReview(review)}
                />
              ))}
        </div>
      </div>
    </section>
  )
}

export default React.memo(Reviews)

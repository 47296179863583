import React from "react"
import Img from "gatsby-image"
import doctorImg from "../assets/images/Meet the Doctor.png"

export default React.memo(function Experts({ experts, team }) {
  return (
    <section className="container my-5" id="about">
      <div className="row px-3 g-4">
        <div className="col-12 col-lg-9 col-xl-8">
          <h2 className="mb-3">Meet The Doctor</h2>
          <div>
            <div className="meet-kenny">
              <div
                className="meet-kenny__pic"
                style={{ marginTop: "0.8rem", marginRight: "1rem" }}
              >
                <img
                  src={doctorImg}
                  alt="Dr. Kenny Snook"
                  height="400"
                  // style={{ maxHeight: "30em" }}
                />
              </div>
              <div className="meet-kenny__text">
                <p className="mt-2">
                  Dr. Kenny Snook grew up in Davenport, Iowa and received his
                  bachelor’s degree from the University of Iowa and his
                  doctorate from Palmer College of Chiropractic. Dr. Snook moved
                  to Chicago in 2014 and started Urban Care Chiropractic in
                  2015. He is a board-certified chiropractic physician licensed
                  in the state of Illinois and is a member of the Illinois
                  Chiropractic Society.
                </p>
                <p className="mt-2">
                  Dr. Snook believes that true health is achieved by making
                  healthy lifestyle choices and taking care of oneself, mind and
                  body. He was drawn to the chiropractic profession because of
                  its holistic, all-natural approach to healthcare which
                  resonated with his own way of living and philosophy.
                </p>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}>
              <h3 className="card-subtitle mb-2 text-muted h4">
                Chiropractic Philosophy
              </h3>
              <p>
                Chiropractic is based on the body’s ability to heal itself once
                subluxations (misalignments) of the spine are removed, allowing
                the body and nervous system to function at 100%.
              </p>
              <p>
                There are 3 different types of stress that can cause
                misalignments of the spine and other joints of the body.
              </p>
              <ul className="list-group">
                <li className="list-group-item">
                  <strong>Physical Stress</strong>: includes poor posture,
                  trauma or injury, exercise, and work activities (repetitive
                  use). In some cases the lack of activity can also lead to
                  dysfunctions of the body.
                </li>
                <li className="list-group-item">
                  <strong>Emotional Stress</strong>: the mind-body connection
                  plays a major role in how your state of mind can affect your
                  body physically. Emotional or mental stress can manifest
                  physically in the body and lead to pain and other symptoms.
                </li>
                <li className="list-group-item">
                  <strong>Chemical Stress</strong>: the type of food and drinks
                  you consume determine your health at the cellular level and
                  ultimately how effectively your body repairs itself and
                  functions. A build-up of toxins in the body can prevent your
                  body from functioning optimally. You are what you eat!
                </li>
              </ul>
            </div>
          </div>
        </div>
        <section className="col-12 col-xl-4 col-lg-3">
          <h2 className="mb-3">Meet Nancy</h2>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {experts
              .sort((a, b) => {
                if (a.name > b.name) return -1
                if (a.name < b.name) return 1
                return 0
              })
              .map((expert, i) => (
                <div className="accordion-item" key={expert.id}>
                  <h3 className="accordion-header" id={`flush-heading${i + 1}`}>
                    <button
                      className={`accordion-button bg-light ${
                        i !== 0 ? "collapsed" : ""
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${i + 1}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapse${i + 1}`}
                    >
                      {expert.name}
                    </button>
                  </h3>
                  <div
                    id={`flush-collapse${i + 1}`}
                    className={`accordion-collapse collapse ${
                      i === 0 ? "show" : ""
                    }`}
                    aria-labelledby={`flush-heading${i + 1}`}
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body bg-light">
                      <div className="row mb-2">
                        <Img
                          fluid={expert.image.asset.fluid}
                          alt={expert.name}
                          style={{
                            maxWidth: "8em",
                            height: "10em",
                            objectFit: "cover",
                          }}
                          className="col-5"
                        />
                        <p
                          className="col-7 text-muted"
                          style={{ fontSize: "0.9em" }}
                        >
                          {expert.specialty}
                        </p>
                      </div>
                      <p className="fw-bold">{expert.motto}</p>
                      <p>{expert.description}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </section>
      </div>
    </section>
  )
})

import React, { useState } from "react"
import Img from "gatsby-image"
import marked from "marked"

function Modal({ data, handleClose, show }) {
  return (
    <>
      <div
        className={`modal fade`}
        id="serviceModal"
        data-bs-backdrop="true"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="serviceModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="serviceModalLabel">
                {data?.name}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleClose()}
              ></button>
            </div>
            {data?.description && (
              <div
                className="modal-body"
                dangerouslySetInnerHTML={{ __html: marked(data?.description) }}
              />
            )}

            <div className="modal-footer">
              {data?.demo && (
                <a
                  className="btn btn-primary"
                  href={data.demo}
                  target="_blank"
                  rel="noopener"
                >
                  Demo →
                </a>
              )}
              <a
                className="btn btn-dark"
                href="https://urbancarechiropractic.schedulista.com/"
                target="_blank"
                rel="noopener"
              >
                Appointment →
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(function Services({ services }) {
  const [show, setShow] = useState(false)
  const [selectedService, setSelectedService] = useState(null)

  return (
    <section className="container my-5" id="services">
      <div className="row px-3 g-4">
        <div className="col">
          <h1 className="mb-3">Services</h1>
        </div>
      </div>
      <div className="row px-3 row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6">
        {services
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
          .map(service => (
            <button
              className="btn btn-link col text-center rounded-3 link-dark text-decoration-none p-2 p-sm-4 mb-4 services__card"
              data-bs-toggle="modal"
              data-bs-target="#serviceModal"
              key={service.id}
              onClick={() => {
                setShow(true)
                setSelectedService(service)
              }}
            >
              <div className="d-flex align-items-center justify-content-center my-3">
                <Img
                  fluid={service.image.asset.fluid}
                  alt={service.name}
                  style={{
                    maxWidth: "100%",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </div>
              <h2 className="fs-3">{service.name}</h2>
            </button>
          ))}

        <Modal
          data={selectedService}
          handleClose={() => setShow(false)}
          show={show}
        />
      </div>
    </section>
  )
})

import React from "react"
import train from "../assets/images/icons/train.svg"
import bus from "../assets/images/icons/bus.svg"
import car from "../assets/images/icons/car.svg"

export default React.memo(function Location() {
  return (
    <section className="container my-5" id="location">
      <div className="row px-3">
        <div className="col">
          <h2 className="mb-3 h1">Location</h2>
        </div>
      </div>
      <div className="row px-3 g-4">
        <div className="col-12 col-lg-4">
          <div className="pb-4">
            <p className="mb-0">
              <i className="text-muted">1335 West Lake St. #102</i>
            </p>
            <p className="mb-0">
              <i className="text-muted">Chicago, IL 60607</i>
            </p>
          </div>
          <div className="">
            <div className="mb-3">
              <h3 className="h5">
                <img src={train} alt="Train" style={{ height: "1em" }} /> Train
              </h3>
              <p className="mb-2">
                Ashland/Lake:
                <span
                  className="px-2 py-1 rounded-1 text-light"
                  style={{ backgroundColor: "#449847", margin: "0 0.3rem" }}
                >
                  Green
                </span>
                <span
                  className="px-2 py-1 rounded-1 text-light"
                  style={{ backgroundColor: "#D584A6" }}
                >
                  Pink
                </span>
              </p>
            </div>
            <div className="mb-3">
              <h3 className="h5">
                <img
                  src={bus}
                  alt="Bus"
                  style={{ height: "1em", marginRight: "0.3rem" }}
                />
                Bus
              </h3>
              <p className="mb-2">
                Ashland/Lake:
                <span
                  className="px-3 py-1 rounded-1 text-light"
                  style={{ backgroundColor: "#3366AC", marginLeft: "0.3rem" }}
                >
                  #9
                </span>
              </p>
            </div>
            <div className="mb-3">
              <h3 className="h5">
                <img
                  src={car}
                  alt="Car"
                  style={{ height: "1em", marginRight: "0.3rem" }}
                />
                Car
              </h3>
              <p className="mb-2">
                We have ample street parking around the neighborhood.
              </p>
              <p className="mb-2">
                Parking available on the following streets: West Lake, West
                Randolph, North Ada and West Fulton Market.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 d-flex align-items-start justify-content-center justify-content-lg-end">
          <iframe
            title="Urban Care Chiropractic location in Google maps."
            className="bg-light"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11881.41680981045!2d-87.660354!3d41.885239!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xab2e1c0f53d7c08!2sUrban%20Care%20Chiropractic!5e0!3m2!1sen!2ses!4v1632778024821!5m2!1sen!2ses"
            style={{ width: "100%", height: "50vh", border: "0" }}
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            id="map"
          />
        </div>
      </div>
    </section>
  )
})
